/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useRouter } from 'next/router';
import speechBubbleSvg from '../../assets/images/Speech-bubble.svg';
import heartSvg from '../../assets/images/heart-red.svg';
import heartUnselectedSvg from '../../assets/images/heart-icon.svg';
import { ApplicationState } from '../../Store';
import { PostActions } from '../../Store/Post/Post';
import { IForumPostModel } from '../../Models/ForumPostModel';
import { ICommentModel } from '../../Models/CommentModel';
import LikeService from '../../Services/Likes/LikeService';
import { IDeleteLikeRequest, ILikeCommentRequest, ILikePostRequest } from '../../Models/LikeModel';
import { CommentActions, SetCommentActionEnum } from '../../Store/Comment/Comment';
import { LikeActions } from '../../Store/Like/Like';
import { IUserPost } from '../../Models/User';
import { hasValidToken } from '../../Auth/AuthUtils';
import { ILikeAction, redirectToStorage, likeActionStorage } from '../../utils/LocalStorage';
import { shouldLike, takePostCount } from '../../utils/Helper';
import { SubCategoryIdEnum } from '../../Models/Category';
import { RecipeModel } from '../../Models/RecipeModel';

interface ExposedProps {
  likeId: string | null;
  post?: IForumPostModel | IUserPost | RecipeModel | null;
  comment?: ICommentModel;
  postLinkTo?: string;
}

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ExposedProps & ReduxProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timeOutFunction: any = null;

const ForumActions = (props: Props): JSX.Element => {
  const {
    likeId,
    post,
    comment,
    getPostById,
    removeLike,
    getLikesByUserPostId,
    getPostsByCategoryId,
    postLinkTo,
    setRepliedToComment,
    setComment
  } = props;
  const data = post || comment;
  const router = useRouter();
  const [tokenValid, setTokenValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    const likeThis = post ? shouldLike(post.id, '') : shouldLike(data?.postId as string, data?.id as string);
    if (!likeThis) return;

    onLikeClick();
    localStorage[likeActionStorage] = '';
  }, []);

  React.useEffect(() => {
    setTokenValid(hasValidToken());
  }, []);

  const onLikeClick = async () => {
    if (!tokenValid) {
      localStorage[redirectToStorage] = router.asPath;
      localStorage[likeActionStorage] = post
        ? JSON.stringify({ postId: data?.id, isComment: false } as ILikeAction)
        : JSON.stringify({ postId: data?.postId, commentId: data?.id, isComment: true } as ILikeAction);
      router.push('/signin');
      return;
    }

    clearTimeout(timeOutFunction);

    timeOutFunction = setTimeout((): void => {
      if (likeId) {
        deleteLike();
      } else {
        createLike();
      }
    }, 700);
  };

  const deleteLike = async () => {
    await LikeService.deleteLike({
      isComment: !post,
      likeId,
      postId: data?.postId,
      commentId: data?.id
    } as IDeleteLikeRequest);

    if (post) {
      getPostById(data?.id as string);
      getPostsByCategoryId(
        {
          categoryId: (data as IForumPostModel).categoryId,
          skip: 0,
          take: takePostCount,
          subCategoryId: SubCategoryIdEnum.ALL
        },
        true
      );
    } else {
      const _comment = comment as ICommentModel;
      setComment({ ..._comment, likeCount: _comment.likeCount - 1 }, SetCommentActionEnum.COMMENTCHANGED);
    }

    removeLike(data?.id as string);
  };

  const createLike = async () => {
    if (post) {
      await LikeService.createPostLike({ postId: data?.id } as ILikePostRequest);
      getPostById(data?.id as string);
      getPostsByCategoryId(
        {
          categoryId: (data as IForumPostModel).categoryId,
          skip: 0,
          take: takePostCount,
          subCategoryId: SubCategoryIdEnum.ALL
        },
        true
      );
    } else {
      await LikeService.createCommentLike({ commentId: data?.id, postId: data?.postId } as ILikeCommentRequest);
      const _comment = comment as ICommentModel;
      setComment({ ..._comment, likeCount: _comment.likeCount + 1 }, SetCommentActionEnum.COMMENTCHANGED);
    }

    getLikesByUserPostId(data?.postId as string, true);
  };

  const onCommentClick = () => {
    if (postLinkTo) router.push(`${postLinkTo}`);
    else {
      router.push('#replytextarea');
      window.document.getElementById('replytextarea')?.focus();
    }
  };

  return (
    <div className="listItemActionsContainer">
      <div className="listActions" style={{ marginLeft: 0 }}>
        {likeId ? (
          <img
            alt="likebtn"
            src={heartSvg}
            style={{ cursor: 'pointer', width: 20, height: 20 }}
            onClick={() => onLikeClick()}
          />
        ) : (
          <img
            alt="likebtn"
            src={heartUnselectedSvg}
            style={{ cursor: 'pointer', width: 20, height: 20 }}
            onClick={() => onLikeClick()}
          />
        )}
        <span style={{ marginLeft: 5 }}>{data?.likeCount}</span>
      </div>
      {post ? (
        <div className="listActions speech-bubble" onClick={() => onCommentClick()}>
          <img alt="speechbubble" src={speechBubbleSvg} style={{ cursor: 'pointer', width: 20, height: 20 }} />
          <span style={{ marginLeft: 5 }}>{(data as any).commentCount}</span>
        </div>
      ) : tokenValid ? (
        <div
          className="listActions speech-bubble"
          onClick={() =>
            setRepliedToComment({
              content: comment?.content ?? '',
              userId: comment?.userId ?? '',
              userName: comment?.userUserName ?? ''
            })
          }
        >
          <a href="#replytextarea" style={{ display: 'flex' }}>
            <img alt="speechbubble" src={speechBubbleSvg} style={{ cursor: 'pointer', width: 20, height: 20 }} />
          </a>
        </div>
      ) : null}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const connector = connect((state: ApplicationState, ownProps: ExposedProps) => state.post, {
  ...PostActions,
  ...CommentActions,
  ...LikeActions
});

export default connector(ForumActions);
