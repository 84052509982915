/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Moment from 'moment';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { IForumPostModel } from '../Models/ForumPostModel';
import userSvg from '../assets/images/user.svg';
import ForumActions from './ForumActions/ForumActions';
import { IUserPost } from '../Models/User';
import { CategoryIdEnum, createNewPostSubCategories, SubCategoryIdEnum } from '../Models/Category';
import { redirectToStorage } from '../utils/LocalStorage';
import { hasValidToken } from '../Auth/AuthUtils';
import { RecipeModel } from '../Models/RecipeModel';
import recipeStyles from '../Recipes/recipe.module.scss';

interface ExposedProps {
  post?: IForumPostModel | RecipeModel | null;
  userPost?: IUserPost;
  likeId: string | null;
  disablePostActions?: boolean;
}

type Props = ExposedProps;

const PreviewPost = (props: Props): JSX.Element => {
  const { post, likeId, userPost, disablePostActions } = props;

  const router = useRouter();

  const data = post || userPost;

  const onUserProfileClick = () => {
    if (!hasValidToken()) {
      localStorage[redirectToStorage] = `/profil/${data?.userId}`;
      router.push('/signin');
      return;
    }

    router.push(`/profil/${data?.userId}`);
  };

  const renderForumPosts = (): JSX.Element => {
    const _data = data as IForumPostModel;

    return (
      <>
        <div className="avatar-container">
          {_data?.anonymous ? (
            <img alt="userpic" src={userSvg} style={{ width: 40, height: '100%', marginRight: 10 }} />
          ) : (
            <a onClick={() => onUserProfileClick()} className="avatar profile-picture-hover">
              {data?.userPictureUrl ? (
                <img src={data.userPictureUrl} alt="pic url" className="profile-picture" />
              ) : (
                <img alt="userpic" src={userSvg} style={{ width: 40, height: '100%' }} />
              )}
            </a>
          )}

          <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div className="post-username">
                {_data?.anonymous ? (
                  <div className="anonymous-profile-name">Anonym</div>
                ) : (
                  <a
                    className="user-name-hover"
                    onClick={() => onUserProfileClick()}
                    style={{ fontSize: 14, color: '#0C7D89' }}
                  >
                    {data?.userUserName}
                  </a>
                )}
              </div>
              <span className="category-tag">
                {
                  createNewPostSubCategories.find(
                    (v) => v.id === (SubCategoryIdEnum as any)[(data as any).subCategoryId]
                  )?.value
                }
              </span>
            </div>

            <div>
              <span className="post-date" style={{ fontSize: isMobile ? 10 : 15 }}>
                {Moment(data?.createdUtc).format('YYYY-MM-DD')}
              </span>
            </div>
            <div>
              {(data as IForumPostModel)?.modified ? (
                <span className="post-date" style={{ fontStyle: 'italic', fontSize: isMobile ? 10 : 15 }}>
                  Redigerad
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <Link href={data!.slug}>
          <div>
            {(data as any).title ? (
              <div className="post-title">
                <span className="post-title-text">{(data as any).title}</span>
              </div>
            ) : null}
          </div>
          <div className="first-comment-text">
            <span className="post-title-text">{_data?.content}</span>
          </div>
          {_data.imageUrl && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={_data.imageUrl} alt="postimage" style={{ maxWidth: '100%' }} />
            </div>
          )}
        </Link>
      </>
    );
  };

  const renderRecipePosts = (): JSX.Element => {
    const _data = data as RecipeModel;

    return (
      <Link href={data!.slug}>
        <div className={recipeStyles['recipe-title']}>{_data.title}</div>
        <div>
          {_data.imgUrl ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={_data.imgUrl}
                alt="postimage"
                className={recipeStyles['recipe-img']}
                style={{ maxWidth: '100%' }}
              />
            </div>
          ) : null}
        </div>
      </Link>
    );
  };

  return (
    <div className="listContainer" key={data?.id}>
      <div style={{ width: '100%' }}>
        {data?.categoryId !== CategoryIdEnum.RECIPE ? renderForumPosts() : renderRecipePosts()}

        {disablePostActions ? null : <ForumActions likeId={likeId} post={data} postLinkTo={data!.slug} />}
      </div>
    </div>
  );
};

export default PreviewPost;
